<script setup>
import { toRefs, ref, onMounted, onBeforeUnmount } from "vue";
import InteractiveImage from "@/components/shared/InteractiveImage.vue";

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  pins: {
    type: Array,
    required: true,
  },
});
const { src, pins } = toRefs(props);

const emit = defineEmits({
  clickPin: ({ unit }) =>
    typeof unit === "undefined" || typeof unit === "string",
});

const plan = ref(null);

let isMobile = false;
const overlayVisible = ref(false);
const toggleOverlay = () => {
  overlayVisible.value = !overlayVisible.value;
};
onMounted(() => {
  isMobile = window.innerWidth < 640;
  if (isMobile) {
    toggleOverlay();
    return;
  }

  plan.value.addEventListener("mouseover", toggleOverlay);
  plan.value.addEventListener("mouseout", toggleOverlay);
});
onBeforeUnmount(() => {
  if (isMobile) return;

  plan.value.removeEventListener("mouseover", toggleOverlay);
  plan.value.removeEventListener("mouseout", toggleOverlay);
});

const clickPin = ({ unit }) => {
  emit("clickPin", { unit });
};

const dialog = ref(null);
const visible = ref(false);

let initialTransform;
const transformed = ({ width, x, y }) => {
  initialTransform = {
    width,
    x,
    y,
  };
};

const show = () => {
  const { top } = plan.value.getBoundingClientRect();
  initialTransform = {
    top,
  };
  visible.value = true;

  window.addEventListener("keydown", escape);
};

const maximizeDialog = () => {
  dialog.value.maximized = true;
};
const hide = () => {
  reinitialize();
  visible.value = false;

  window.removeEventListener("keydown", escape);
};
const escape = ({ key }) => {
  if (key !== "Escape") return;
  hide();
};

const reinitialized = ref(true);
const reinitialize = () => {
  reinitialized.value = false;
  setTimeout(() => (reinitialized.value = true));
};
</script>

<template>
  <div
    ref="plan"
    class="border border-surface-200 rounded-md overflow-hidden w-full bg-primary-inverse"
  >
    <div class="relative" @click="show">
      <div
        v-if="!visible"
        class="z-10 absolute t-0 l-0 w-full h-1/3 rounded-t-md pt-2 flex justify-center pointer-events-none"
        :class="overlayVisible ? 'opacity-100' : 'opacity-0'"
      >
        <div class="relative inline-block">
          <span
            class="relative z-10 text-primary-inverse border border-transparent rounded-b-lg text-sm bg-surface-900/50 shadow-md p-3"
          >
            Tap to explore the interactive plan.
          </span>
        </div>
      </div>
      <InteractiveImage
        v-if="reinitialized"
        :src="src"
        :pins="pins"
        :showZoomPercentage="!overlayVisible"
        disableGestures
        @transformed="transformed"
        @clickPin="clickPin"
      />
      <p-button
        icon="pi pi-arrow-up-right-and-arrow-down-left-from-center"
        class="!absolute bottom-1 right-1"
        @click="show"
      ></p-button>
    </div>
    <p-dialog
      ref="dialog"
      :visible="visible"
      maximizable
      @show="maximizeDialog"
      :showHeader="false"
      contentClass="relative !p-0"
    >
      <InteractiveImage
        :src="src"
        :fitImageHeight="false"
        :initialTransform="initialTransform"
        :pins="pins"
        @clickPin="clickPin"
        @click="hide"
      />
      <p-button
        icon="pi pi-arrow-down-left-and-arrow-up-right-to-center"
        class="!absolute bottom-1 right-1"
        @click="hide"
      ></p-button>
    </p-dialog>
  </div>
</template>
